import * as React from "react"
import { FC, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Seo from "../../../components/Seo"
import Layout from "../../../components/Layout"

const Container = styled.div``

interface Props {}

const Redirect: FC<Props> = () => {
  useEffect(() => {
    navigate("https://www.socialsupermarket.org/product-category/hampers-and-gift-boxes/")
  })
  return (
    <Layout fullWidth>
      <Seo
        title={`Social Enterprise Hampers & Gift Boxes`}
        description={`Social Enterprise Hampers & Gift Boxes`}
      />
      <div>Redirecting</div>
    </Layout>
  )
}

export default Redirect
